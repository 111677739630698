import React from "react"

// Styles
import "../styles/components/footer.scss"

export default function footer() {
  return (
    <footer className="footer">
      <div className="footer__copyright">
        <p>
          © Copyright {new Date().getFullYear()} Gunda Power. All Rights
          Reserved.
        </p>

        <a
          href="https://www.nugo.lk"
          target="blank"
          rel="nofollow"
          style={{ color: "#2980b9" }}
        >
          Developed by Nugo
        </a>
      </div>
    </footer>
  )
}

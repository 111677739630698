import React, { useState } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { logout } from '../state/actions/auth'
import Logo from './logo'
import NavDrawer from './navDrawer'

// Styles
import styles from '../styles/components/header.module.scss'
import { NavDropdown } from 'react-bootstrap'
import { FaUser, FaUserLock } from 'react-icons/fa'

const Header = ({ isLoggedIn, logout, user }) => {
  const handleLogout = () => {
    logout()
  }

  return (
    <div id='top'>
      <header className={styles.header}>
        <ul className={styles.navbar}>
          <Link to='/app/products' className={styles.logo}>
            <Logo />
          </Link>
          <Link to='/app/products' className={styles.logoMobile}>
            <Logo />
          </Link>

          <div className={styles.navdrawer}>
            <NavDrawer />
          </div>

          <div className={styles.links}>
            {isLoggedIn && (
              <li>
                {/* <Link
                  to='/app/products'
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  Products
                </Link>
                <Link
                  to='/app/quotations'
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  Quotations
                </Link>
                <Link
                  to='/app/invoices'
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  Invoices
                </Link>
                <Link
                  to='/app/delivery-notes'
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  Delivery Notes
                </Link>
                <Link
                  to='/app/payment-receipts'
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  Payment Receipts
                </Link>
                <Link to='/app/customers' activeClassName={styles.active}>
                  Customers
                </Link>

                {admin && (
                  <Link to='/app/reports' activeClassName={styles.active}>
                    Reports
                  </Link>
                )} */}

                {/* User Dropdown */}
                <NavDropdown
                  title={<FaUser style={{ fontSize: '1.5rem' }} />}
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '6px'
                  }}
                >
                  {/* <NavDropdown.Divider /> */}
                  <NavDropdown.Item
                    onClick={handleLogout}
                    style={{
                      margin: 0,
                      padding: '1rem 2rem',
                      color: 'red',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem'
                    }}
                  >
                    <FaUserLock /> Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
            )}
          </div>
        </ul>
      </header>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    admin: state.auth?.auth?.user?.admin,
    user: state.auth?.auth?.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)

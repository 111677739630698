import React from "react"
import "../styles/components/hamburger.scss"

const HamburgerIcon = props => {
  return (
    <div
      id="nav-icon1"
      role="button"
      onKeyDown={props.onClickHandler}
      className={props.menuOpen ? "open" : "close"}
      onClick={props.onClickHandler}
      tabIndex={0}
    >
      <span style={{ background: "blue" }} />
      <span />
      <span />
    </div>
  )
}

export default HamburgerIcon

import React, { useState } from "react"
import { connect } from "react-redux"
import { logout } from "../state/actions/auth"
import Header from "./header"
import Footer from "./footer"
import styles from "../styles/components/layout.module.scss"
import { Link } from "gatsby"
import { ToastContainer } from "react-toastify"
import { Button } from "react-bootstrap"
import {
  FaArrowRight,
  FaArrowLeft,
  FaHome,
  FaFileInvoiceDollar,
  FaFileInvoice,
  FaUserAlt,
  FaTruck,
  FaCashRegister,
  FaChartBar,
  FaDownload,
  FaTools,
  FaExchangeAlt,
  FaStore,
  FaList,
} from "react-icons/fa" // Import the required icons

const Layout = ({
  isLoggedIn,
  admin,
  reports,
  products,
  replacementProducts,
  customers,
  children,
  technicalTeam,
  officeTeam,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <main className={styles.layout}>
        {isLoggedIn &&
        ((officeTeam && products && customers) ||
          (technicalTeam && replacementProducts && customers)) ? (
          <aside
            className={`${styles.sidebar} ${sidebarOpen ? styles.open : ""}`}
          >
            <div className={styles.links}>
              {/* Office Team Links */}
              {officeTeam && products && customers && (
                <>
                  <Link
                    to="/app/products"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaHome />
                    Products
                  </Link>

                  <Link
                    to="/app/quotations"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaFileInvoiceDollar />
                    Quotations
                  </Link>
                  <Link
                    to="/app/invoices"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaFileInvoice />
                    Invoices
                  </Link>
                  <Link
                    to="/app/delivery-notes"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaTruck />
                    Delivery Notes
                  </Link>
                  <Link
                    to="/app/payment-receipts"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaCashRegister />
                    Payment Receipts
                  </Link>
                  <Link to="/app/customers" activeClassName={styles.active}>
                    <FaUserAlt />
                    <span>Customers</span>
                  </Link>
                  {(admin || reports) && (
                    <Link to="/app/reports" activeClassName={styles.active}>
                      <FaChartBar />
                      Reports
                    </Link>
                  )}
                  <Link
                    to="/app/product-catalog"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaDownload />
                    Product Catalog
                  </Link>
                  <Link
                    to="/app/credit-notes"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaFileInvoiceDollar />
                    Credit Notes
                  </Link>
                </>
              )}

              {/* Technical Team Links */}
              {technicalTeam && replacementProducts && customers && (
                <>
                  <Link
                    to="/app/serial-database"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaList />
                    Serial Database
                  </Link>
                  <Link
                    to="/app/replacement-products"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaStore />
                    Replacement Products
                  </Link>
                  <Link
                    to="/app/troubleshoots"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaTools />
                    TroubleShoots
                  </Link>
                  <Link
                    to="/app/issue-replacements"
                    activeClassName={styles.active}
                    partiallyActive={true}
                  >
                    <FaExchangeAlt />
                    Issue Replacements
                  </Link>
                </>
              )}
            </div>
            <Button onClick={handleSidebarToggle} className={styles.toggle}>
              {sidebarOpen ? <FaArrowRight /> : <FaArrowLeft />}
            </Button>
          </aside>
        ) : null}
        <div className={styles.content}>{children}</div>
      </main>

      <Footer />
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    admin: state.auth?.auth?.user?.admin,
    reports: state.auth?.auth?.user?.reports,
    products: state.product.products,
    replacementProducts: state.replacementProduct.replacementProducts,
    customers: state.customer.customers,
    technicalTeam: state.auth?.auth?.user?.technicalTeam,
    officeTeam: state.auth?.auth?.user?.officeTeam,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { connect } from "react-redux"
import { logout } from "../state/actions/auth"
import HamburgerIcon from "./HamburgerIcon"

// Styles
import { Button } from "react-bootstrap"
import "../styles/components/navDrawer.scss"

// Icons
import {
  FaArrowRight,
  FaArrowLeft,
  FaHome,
  FaFileInvoiceDollar,
  FaFileInvoice,
  FaUserAlt,
  FaTruck,
  FaCashRegister,
  FaChartBar,
  FaDownload,
  FaTools,
  FaExchangeAlt,
  FaStore,
  FaList,
} from "react-icons/fa"

const styles = {
  list: {
    width: "100%",
  },
  paper: {
    width: 300,
    background: "white",
    fontSize: "1.2rem",
  },
}

class TemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props
    const sideList = (
      <div>
        <div className={classes.list}>
          <div>
            <StaticQuery
              query={graphql`
                query {
                  logo: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              `}
              render={data => (
                <div
                  style={{
                    alignSelf: `left`,
                    width: `200px`,
                    height: `auto`,
                    margin: 25,
                  }}
                >
                  <Img fluid={data.logo.childImageSharp.fluid} />
                </div>
              )}
            />
          </div>
          {this.props.isLoggedIn && (
            <List>
              {this.props.officeTeam &&
                this.props.products &&
                this.props.customers && (
                  <>
                    <Link to="/app/products">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaHome /> <span>Products</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/quotations">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaFileInvoiceDollar /> <span>Quotations</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/invoices">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaFileInvoice /> <span>Invoices</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/delivery-notes">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaTruck /> <span>Delivery Notes</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/payment-receipts">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaCashRegister /> <span>Payment Receipts</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/customers">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaUserAlt /> <span>Customers</span>
                        </h5>
                      </ListItem>
                    </Link>
                    {(this.props.admin || this.props.reports) && (
                      <Link to="/app/reports">
                        <ListItem>
                          <h5 className="navDrawer__link">
                            <FaChartBar /> <span>Reports</span>
                          </h5>
                        </ListItem>
                      </Link>
                    )}
                    <Link to="/app/product-catalog">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaDownload /> <span>Product Catalog</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/credit-notes">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaFileInvoiceDollar /> <span>Credit Notes</span>
                        </h5>
                      </ListItem>
                    </Link>
                  </>
                )}
              {this.props.technicalTeam &&
                this.props.replacementProducts &&
                this.props.customers && (
                  <>
                    <Link to="/app/serial-database">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaList /> <span>Serial Database</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/replacement-products">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaStore /> <span>Replacement Products</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/troubleshoots">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaTools /> <span>TroubleShoots</span>
                        </h5>
                      </ListItem>
                    </Link>
                    <Link to="/app/issue-replacements">
                      <ListItem>
                        <h5 className="navDrawer__link">
                          <FaExchangeAlt /> <span>Issue Replacements</span>
                        </h5>
                      </ListItem>
                    </Link>
                  </>
                )}
              <ListItem>
                <Button
                  variant="danger"
                  style={{
                    textTransform: "uppercase",
                    texTransform: "uppercase",
                    padding: "10px 20px",
                    marginTop: "auto",
                    fontWeight: 600,
                    width: "100%",
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
              </ListItem>
            </List>
          )}
        </div>
      </div>
    )

    return (
      <div>
        <div className="hamburgerIcon">
          <HamburgerIcon
            onClickHandler={this.toggleDrawer("right", true)}
            style={{ cursor: "pointer" }}
            menuOpen={this.state.right}
          />
          <Drawer
            classes={{ paper: classes.paper }}
            anchor="left"
            open={this.state.right}
            onClose={this.toggleDrawer("right", false)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer("right", false)}
              onKeyDown={this.toggleDrawer("right", false)}
            >
              {sideList}
            </div>
          </Drawer>
        </div>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  admin: PropTypes.bool,
  reports: PropTypes.bool,
  products: PropTypes.array,
  replacementProducts: PropTypes.array,
  customers: PropTypes.array,
  technicalTeam: PropTypes.bool,
  officeTeam: PropTypes.bool,
  logout: PropTypes.func.isRequired,
}

const TemporaryDrawerWrapper = withStyles(styles)(TemporaryDrawer)

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    admin: state.auth?.auth?.user?.admin,
    reports: state.auth?.auth?.user?.reports,
    products: state.product.products,
    replacementProducts: state.replacementProduct.replacementProducts,
    customers: state.customer.customers,
    technicalTeam: state.auth?.auth?.user?.technicalTeam,
    officeTeam: state.auth?.auth?.user?.officeTeam,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemporaryDrawerWrapper)

import _ from 'lodash'
import axios from 'axios'
import { toast } from 'react-toastify'

import { isBrowser } from '../utils'

// Auto-login
export const autoLogin = () => {
  return async dispatch => {
    try {
      const user = isBrowser() && window.localStorage.getItem('auth')
      if (!user) {
        return
      }
      const token = JSON.parse(user).token
      const data = await axios.get(`${process.env.GATSBY_API_CALL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const authData = {
        user: data.data,
        token
      }
      dispatch({
        type: 'LOGIN',
        data: authData
      })
      toast.success(`Welcome back ${data.data.name}!`)
    } catch (e) {
      console.log(e)
    }
  }
}

// Login
export const login = auth => {
  return async dispatch => {
    try {
      const data = await axios.post(
        `${process.env.GATSBY_API_CALL}/users/login`,
        auth
      )
      dispatch({
        type: 'LOGIN',
        data: data.data
      })
      isBrowser() && localStorage.setItem('auth', JSON.stringify(data.data))
      toast.success(`Welcome back ${data.data.user.name}!`)
    } catch (e) {
      console.log(e.response)
      toast.error(`Sorry, there was an error logging in.`)
    }
  }
}

// Logout
export const logout = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.post(
        `${process.env.GATSBY_API_CALL}/users/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      dispatch({
        type: 'USER_LOGOUT'
      })
      isBrowser && localStorage.removeItem('auth')
      toast.success(`Successfully logged out.`)
    } catch (e) {
      console.log(e.response)
      toast.error(`Error: ${e.response.data.error.message}`)
    }
  }
}

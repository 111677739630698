import _ from "lodash"
import moment from "moment"

export const isBrowser = () => typeof window !== "undefined"

export const emailValid = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const formatNumber = x => {
  return Number(x)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// Calculate invoice/quotation total
export const getTotal = products => {
  let total = 0
  _.forEach(products, product => {
    if (product.price && product.quantity) {
      let discount

      if (product.newWarranty) {
        discount =
          product.discountType === "LKR"
            ? parseFloat(product.discount) * parseFloat(product.quantity)
            : parseFloat(product.price) *
              parseFloat(product.quantity) *
              parseFloat((product.discount || 0) / 100)
      } else {
        discount =
          product.discountType === "LKR"
            ? parseFloat(product.discount)
            : parseFloat(product.price) *
              parseFloat(product.quantity) *
              parseFloat((product.discount || 0) / 100)
      }

      // const addAmountForWarranty=product.newWarranty && product.newWarranty == '5 Years' ? (parseFloat(product.price) * 15/100) * parseFloat(product.quantity) : 0

      total +=
        parseFloat(product.price) * parseFloat(product.quantity) -
        (discount || 0)
    }
  })
  return total
}

export const vatApplicable = invoiceDate => {
  // Check the date format and parse accordingly
  var givenDate = moment(invoiceDate, ["YYYY-MM-DD", "DD-MM-YYYY"]).toDate()

  // Target date (8th January 2024)
  var targetDate = moment("08-01-2024", "DD-MM-YYYY").toDate()

  // Check if the given date is past the target date
  if (givenDate >= targetDate) {
    return true
  } else {
    return false
  }
}
